import { App } from '@/main'
import { LocaleMessage } from 'vue-i18n'
import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  StatusItem,
  SupportItem,
  SupportList,
  SupportState,
} from '@/store/modules/client/support/types'
import type { TSwarmicaProductInfo } from '@/api/services/lk/types'

import style from '@/assets/styles/_export-var.module.scss'
import {
  PRODUCT,
  CYBER_BACKUP,
  CYBER_BACKUP_PERSONAL,
} from '@/config/constants'

type SupportTree = GetterTree<SupportState, RootState>

export const getters: SupportTree = {
  /**
   * isLoading
   * ? Статус выполнения запросов
   *
   * @param {SupportState} state data
   * @returns {boolean} isLoading
   */
  isLoading: (state: SupportState): boolean => state.isLoading,

  /**
   * swarmicaProductInfo
   * ? Информация о продукте
   *
   * @param {SupportState} state data
   * @returns {TSwarmicaProductInfo | null} информация о продукте
   */
  swarmicaProductInfo: (state: SupportState): TSwarmicaProductInfo => {
    return state.swarmicaProductInfo
  },

  /**
   * swarmicaProductId
   * ? Возвращает id продукта из swarmica
   *
   * @param {SupportState} state data
   * @param {object} getters computed
   * @returns {string | null} идентификатор продукта
   */
  swarmicaProductId: (state: SupportState, getters): string | null => {
    const productId: keyof typeof PRODUCT.MAP_PRODUCT =
      getters['supportProductId']

    const productName = PRODUCT.MAP_PRODUCT[productId]

    const product = state.swarmicaProductInfo.products.find(
      (item) => item.label === productName
    )
    return product?.value ?? null
  },

  /**
   * supportProductId
   * ? Возвращает внутренний id продукта
   *
   * @param {SupportState} state data
   * @param {object} getters computed
   * @param {object} rootState root data
   * @param {object} rootGetters root computed
   * @returns {string} идентификатор продукта
   */
  supportProductId: (
    state: SupportState,
    getters,
    rootState,
    rootGetters
  ): typeof CYBER_BACKUP | typeof CYBER_BACKUP_PERSONAL => {
    const isBusinessUser = rootGetters['account/isRoleBusinessUser']

    if (isBusinessUser) {
      return CYBER_BACKUP
    }
    return CYBER_BACKUP_PERSONAL
  },

  /**
   * iconStatus
   * ? Иконка статуса
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  iconStatus: (state: SupportState) => {
    type Status = StatusItem | undefined
    type Label = {
      [key: string]: LocaleMessage
    }
    const labels: Label = {
      new: App.$i18n.t('component.support-menu.history.status-name.new'),
      hold: App.$i18n.t('component.support-menu.history.status-name.hold'),
      reject: App.$i18n.t('component.support-menu.history.status-name.reject'),
      pending: App.$i18n.t(
        'component.support-menu.history.status-name.pending'
      ),
      resolve: App.$i18n.t(
        'component.support-menu.history.status-name.resolve'
      ),
      closed: App.$i18n.t('component.support-menu.history.status-name.closed'),
      in_work: App.$i18n.t(
        'component.support-menu.history.status-name.in_work'
      ),
      undefined: App.$i18n.t(
        'component.support-menu.history.status-name.undefined'
      ),
    }

    return (status: string) => {
      const current: Status = Object.values(state.statuses).find(({ list }) => {
        return list.includes(status)
      })

      if (!current) {
        return {
          name: 'circle-question',
          label: labels.undefined,
          color: style.colorAvBrandPrimary,
        }
      }

      const label = current.icon.status

      return { ...current.icon, label: labels[label] }
    }
  },

  /**
   * supportList
   * ? Список обращений
   *
   * @param {SupportState} state data
   * @returns {SupportList} список обращений
   */
  supportList: (state: SupportState): SupportList => {
    return state.supports
  },

  /**
   * supportItem
   * ? Детализированный объект обращения
   *
   * @param {SupportState} state data
   * @returns {SupportItem | null} детализированный объект обращения
   */
  supportItem: (state: SupportState): SupportItem | null => {
    return state.support
  },

  /**
   * isVisibleSupportMenu
   * ? Признак видимости меню поддержки
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  isVisibleSupportMenu: (state: SupportState): boolean => {
    return state.isVisibleSupportMenu
  },

  /**
   * isOpenSupportAppeal
   * ? Признак открытого обращения поддержки
   *
   * @param {SupportState} state data
   * @returns {boolean} признак открытого обращения поддержки
   */
  isOpenSupportAppeal: (state: SupportState): boolean => {
    return state.support !== null
  },
}
