import { ICompanyResponse } from '@/api/services/account/types'
import { ICompany, type TCountry } from '@/api/types'
import { COUNTRIES } from '@/config/constants'

export const getCountryCode = (
  code: Uppercase<TCountry> | TCountry
): TCountry => code.toLowerCase() as TCountry

export const isRuCountry = (code: TCountry | Uppercase<TCountry>): boolean => {
  return getCountryCode(code) === COUNTRIES.RU
}

export const getCompanyFromResponse = (
  company: ICompanyResponse
): ICompany => ({
  id: company.id,
  type: company.organization_type,
  name: company.name,
  address: company.address,
  kpp: company.kpp,
  ogrn: company.ogrn,
  oked: company.oked,
  country: getCountryCode(company.country_code),
  inn: isRuCountry(company.country_code) ? company.inn : '',
  unp: isRuCountry(company.country_code) ? '' : company.inn,
})
