import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IFilesParams } from '@/helpers/types'
import type {
  TUrlSearchParams,
  TFilesEndpointKeys,
  IFilesFolderParams,
  IFilesBackupParams,
  IFilesVersionParams,
  IFilesMultipleParams,
  IFilesDownloadParams,
} from '@/store/modules/client/files/types'

type TFilesAction = ActionTree<null, RootState>
type TFilesContext = ActionContext<null, RootState>

import axios from '@/api/axios'
import { saveAs } from 'file-saver'

const ENDPOINTS = {
  FILE: '/api/webrestore/v1/file',
  FILES: '/api/webrestore/v1/files',
  BACKUP: '/api/webrestore/v1/backup',
  VERSION: '/api/webrestore/v1/version',
}

/**
 * getParams
 * ? Получить параметры запроса
 *
 * @param {string} id идентификатор сессии бэкапа
 * @param {TFilesEndpointKeys} params парамеры бэкапа
 * @param {boolean} check_only признак проверки на скачивание бэкапа
 * @returns {Function} функция параметров запроса
 */
const getParams = (
  id: string,
  params: TFilesEndpointKeys,
  check_only = false
): TUrlSearchParams => {
  const fullParams: TUrlSearchParams = {
    ...params,
    backup_session_id: id,
    check_only: JSON.stringify(check_only),
  }

  if (!check_only) {
    delete fullParams.check_only
  }

  return fullParams
}

/**
 * getRequestURL
 * ? Получить сформированный адрес запроса
 *
 * @param {string} endpoint адрес запроса
 * @param {TUrlSearchParams} params параметры запроса бэкапа
 * @returns {string} сформированный адрес запроса
 */
const getRequestURL = (endpoint: string, params: TUrlSearchParams): string => {
  const formatQuery = new URLSearchParams(params).toString()

  return `${endpoint}?${formatQuery}`
}

export const actions: TFilesAction = {
  /**
   * downloadFileURL
   * ? Получить состояние текущей операции
   *
   * @param {TFilesContext} ctx context
   * @param {IFilesDownloadParams} options опции скачивания элемента
   * @returns {Promise<void>}
   */
  downloadFileURL: (
    { dispatch, rootGetters }: TFilesContext,
    { endpoint, params }: IFilesDownloadParams
  ): Promise<void> => {
    const downloadFile = () => {
      return dispatch('session/getSession', rootGetters['session/sessionId'], {
        root: true,
      }).then(({ id }) => {
        const checkURL = getRequestURL(endpoint, getParams(id, params, true))

        axios.get(checkURL).then(() => {
          const downloadURL = getRequestURL(endpoint, getParams(id, params))
          saveAs(downloadURL)
        })
      })
    }

    return dispatch('session/generalSessionCreation', downloadFile, {
      root: true,
    })
  },

  /**
   * downloadByFile
   * ? Скачать определенный файла
   *
   * @param {TFilesContext} param0 context
   * @param {IFilesParams} params параметры запроса
   * @param {string} params.name название скачиваемого файла
   * @param {string} params.path путь до скачиваемого бэкапа
   * @returns {Promise<void>}
   */
  downloadByFile: (
    { dispatch }: TFilesContext,
    params: IFilesParams
  ): Promise<void> => {
    const payload = {
      params,
      endpoint: ENDPOINTS.FILE,
    }

    return dispatch('downloadFileURL', payload)
  },

  /**
   * downloadByFolder
   * ? Скачать определенную папку (в архиве)
   *
   * @param {TFilesContext} param0 context
   * @param {IFilesFolderParams} params query параметры
   * @returns {Promise<void>}
   */
  downloadByFolder: (
    { dispatch }: TFilesContext,
    params: IFilesFolderParams
  ): Promise<void> => {
    const payload = {
      params,
      endpoint: ENDPOINTS.FILES,
    }

    return dispatch('downloadFileURL', payload)
  },

  /**
   * downloadByMultipleFile
   * ? Скачать список файлов и папок (в архиве)
   *
   * @param {TFilesContext} param0 context
   * @param {IFilesMultipleParams} params query параметры
   * @returns {Promise<void>}
   */
  downloadByMultipleFile: (
    { dispatch }: TFilesContext,
    params: IFilesMultipleParams
  ): Promise<void> => {
    const { paths } = params

    const payload = {
      params: {
        ...params,
        paths: paths.join(','),
      },
      endpoint: ENDPOINTS.FILES,
    }

    return dispatch('downloadFileURL', payload)
  },

  /**
   * downloadByBackup
   * ? Скачать определенный бэкап со всеми версиями, файлами внутри
   *
   * @param {TFilesContext} param0 context
   * @param {IFilesBackupParams} params query параметры
   * @param {string} params.backup название бэкапа
   * @returns {Promise<void>}
   */
  downloadByBackup: (
    { dispatch }: TFilesContext,
    params: IFilesBackupParams
  ): Promise<void> => {
    const payload = {
      params,
      endpoint: ENDPOINTS.BACKUP,
    }

    return dispatch('downloadFileURL', payload)
  },

  /**
   * downloadByVersion
   * ? Скачать версию бэкапа в виде zip архива
   *
   * @param {TFilesContext} param0 context
   * @param {IFilesVersionParams} params параметры запроса
   * @param {string} params.backup название бэкапа
   * @param {string} params.version версия бэкапа
   * @returns {Promise<void>}
   */
  downloadByVersion: (
    { dispatch }: TFilesContext,
    params: IFilesVersionParams
  ): Promise<void> => {
    const payload = {
      params,
      endpoint: ENDPOINTS.VERSION,
    }

    return dispatch('downloadFileURL', payload)
  },
}
