const gtm = {
  id: process.env.VUE_APP_GTM_ID,
  host: 'https://www.googletagmanager.com',
}

const clever = {
  id: process.env.VUE_APP_CLEVER_ID,
  host: 'static.ctm.1dmp.io',
}

const metric = {
  storage: 'dataLayer',
  use: JSON.parse(process.env.VUE_APP_USE_METRICS),
}

const gtmLoader = () => {
  window[metric.storage] = window[metric.storage] || []
  window[metric.storage].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })

  const f = document.getElementsByTagName('script')[0]
  const j = document.createElement('script')
  const dl = metric.storage != 'dataLayer' ? '&l=' + metric.storage : ''

  j.async = true
  j.src = `${gtm.host}/gtm.js?id=${gtm.id}${dl}`
  f.parentNode.insertBefore(j, f)
}

const cleverLoader = (w, d, s, l, h, m) => {
  w[l] = w[l] || []
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s)
  const dl = l != 'dmpkitdl' ? '&l=' + l : ''
  j.async = true
  j.src = '//' + m + '/tm.js?id=' + h + dl
  f.parentNode.insertBefore(j, f)
}

const gtmFrame = () => {
  const noScriptNode = document.body.querySelector('noscript')
  const iFrameText = `
    <iframe
      width="0"
      height="0"
      src="${gtm.host}/ns.html?id= ${gtm.id}"
      style="display: none; visibility: hidden"
    ></iframe>
  `

  noScriptNode.innerHTML = `
    ${iFrameText}
    ${noScriptNode.innerHTML}
  `
}

if (metric.use) {
  Promise.resolve()
    .then(gtmLoader)
    .then(gtmFrame)
    .then(
      cleverLoader.bind(
        window,
        window,
        document,
        'script',
        metric.storage,
        clever.id,
        clever.host
      )
    )
}
