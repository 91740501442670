<template>
  <div
    ref="scrollbar"
    class="ui-scrollbar"
    :style="variables"
    @touchend="isTouchedScroll = false"
    @touchmove="isTouchedScroll = true"
    @mouseenter="isPressedScroll = true"
    @mouseleave="isPressedScroll = false"
  >
    <slot v-if="disableScrollbar" />
    <template v-else>
      <div
        ref="scrollbar-content"
        class="ui-scrollbar-content"
        @scroll="scrollEvent"
      >
        <slot />
      </div>

      <!-- X -->
      <div
        v-if="isVisibleScrollbarX"
        class="ui-scrollbar-slider-container slider-container-x"
      >
        <div
          ref="scrollbar-slider-content-x"
          class="ui-scrollbar-slider-content slider-content-x"
        >
          <div
            :class="{ visible: isVisibleSlider }"
            class="ui-scrollbar-slider-item slider-item-x"
            @mousedown="dragScrollStart($event, 'X')"
          />
        </div>
      </div>

      <!-- Y -->
      <div
        v-if="isVisibleScrollbarY"
        class="ui-scrollbar-slider-container slider-container-y"
      >
        <div
          ref="scrollbar-slider-content-y"
          class="ui-scrollbar-slider-content slider-content-y"
        >
          <div
            :class="{ visible: isVisibleSlider }"
            class="ui-scrollbar-slider-item slider-item-y"
            @mousedown="dragScrollStart($event, 'Y')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'

const SLIDER_SIZES = {
  sm: {
    sliderSize: 6,
    sliderBorderRadius: 8,
    sliderContainerSize: 12,
  },
  md: {
    sliderSize: 10,
    sliderBorderRadius: 12,
    sliderContainerSize: 20,
  },
}

export default {
  name: 'UiScrollbar',

  props: {
    /**
     * Фиксированная ширина контента
     * ? размер указывается в пикселях
     * ? 0-я ширина подразумевает автоматическое вычисление исходя от родительской ширины
     */
    width: {
      type: Number,
      default: 0,
    },

    /**
     * Фиксированная высота контента
     * ? размер указывается в пикселях
     * ? 0-я высота подразумевает автоматическое вычисление исходя от родительской ширины
     */
    height: {
      type: Number,
      default: 0,
    },

    /**
     * Размер полосы прокрутки
     */
    sliderSize: {
      type: String,
      default: 'sm',
      validator(value) {
        return Object.keys(SLIDER_SIZES).includes(value)
      },
    },

    /**
     * Режим появления ползунка
     * ? ползунок видин только при наведении на контент
     * ? в противном случае всегда видимая
     */
    appearMode: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'hover', 'touch'].includes(value)
      },
    },

    /**
     * Скрыть горизонтальную прокрутку
     */
    hideScrollbarX: {
      type: Boolean,
      default: false,
    },

    /**
     * Скрыть вертикальную прокрутку
     */
    hideScrollbarY: {
      type: Boolean,
      default: false,
    },

    /**
     * Признак отключенного скролла
     */
    disableScrollbar: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentSide: '',

    isHover: false,
    isScrollTop: false,
    isScrollLeft: false,
    isScrollDown: false,
    isScrollRight: false,
    isTouchedScroll: false,
    isPressedScroll: false,
    isCapturedScroll: false,
    isVisibleSlider: false,
    directiveNodeContent: null,

    intervalSliderId: 0,
    intervalMutateObserverId: 0,

    startDragPointX: 0,
    startDragPointY: 0,
    calculatedScrollTop: 0,
    calculatedScrollLeft: 0,

    sizes: {
      scrollTop: 0,
      scrollLeft: 0,
      scrollWidth: 0,
      scrollHeight: 0,

      offsetWidth: 0,
      offsetHeight: 0,

      sliderContentWidth: 0,
      sliderContentHeight: 0,
    },
  }),

  computed: {
    isVerticalSide() {
      return this.currentSide === 'Y'
    },

    isVisibleScrollbarX() {
      return (
        !this.hideScrollbarX && this.sizes.scrollWidth > this.sizes.offsetWidth
      )
    },

    isVisibleScrollbarY() {
      return (
        !this.hideScrollbarY &&
        this.sizes.scrollHeight > this.sizes.offsetHeight
      )
    },

    sliderMaxSize() {
      return this.disableScrollbar ? 0 : 20
    },

    sliderWidth() {
      const actualSliderWidth =
        (this.sizes.sliderContentWidth * this.sizes.offsetWidth) /
        this.sizes.scrollWidth

      return Math.max(actualSliderWidth, this.sliderMaxSize)
    },

    sliderHeight() {
      const actualSliderHeight =
        (this.sizes.sliderContentHeight * this.sizes.offsetHeight) /
        this.sizes.scrollHeight

      return Math.max(actualSliderHeight, this.sliderMaxSize)
    },

    scrollPercentageFromTop() {
      const percentTop =
        this.sizes.scrollTop /
        (this.sizes.scrollHeight - this.sizes.offsetHeight)

      return isNaN(percentTop) ? 0 : percentTop
    },

    scrollPercentageFromLeft() {
      const percentLeft =
        this.sizes.scrollLeft /
        (this.sizes.scrollWidth - this.sizes.offsetWidth)

      return isNaN(percentLeft) ? 0 : percentLeft
    },

    appearModeState() {
      if (this.appearMode === '') {
        return 1
      }

      if (
        this.appearMode === 'hover' &&
        (this.isTouchedScroll || this.isPressedScroll || this.isCapturedScroll)
      ) {
        return 1
      }

      if (this.appearMode === 'touch' && this.isTouchedScroll) {
        return 1
      }

      return 0
    },

    variables() {
      const { sliderSize, sliderBorderRadius, sliderContainerSize } =
        SLIDER_SIZES[this.sliderSize]
      const sliderContainerWidth = this.isVisibleScrollbarY
        ? sliderContainerSize
        : 0
      const sliderContainerHeight = this.isVisibleScrollbarX
        ? sliderContainerSize
        : 0

      return {
        '--scroll-top': `${this.calculatedScrollTop}px`,
        '--scroll-left': `${this.calculatedScrollLeft}px`,

        '--scrollbar-width': this.width > 0 ? `${this.width}px` : '100%',
        '--scrollbar-height': this.height > 0 ? `${this.height}px` : '100%',

        '--slider-size': `${sliderSize}px`,
        '--slider-width': `${this.sliderWidth}px`,
        '--slider-height': `${this.sliderHeight}px`,
        '--slider-visibility': this.appearModeState,
        '--slider-border-radius': `${sliderBorderRadius}px`,
        '--slider-container-size': `${sliderContainerSize}px`,
        '--slider-container-width': `${sliderContainerWidth}px`,
        '--slider-container-height': `${sliderContainerHeight}px`,
      }
    },
  },

  mounted() {
    if (this.disableScrollbar) {
      return
    }

    this.registerEvents()
    this.initSimpleScrollbar()
  },

  updated() {
    this.setScrollbarSizes()
  },

  destroyed() {
    this.removeEvents()
  },

  methods: {
    initSimpleScrollbar() {
      const scrollbarContent = this.$refs['scrollbar-content']

      if (!(scrollbarContent instanceof Element)) {
        return
      }

      // Обрабатывается директивой v-scrollbar
      if (this.directiveNodeContent) {
        scrollbarContent.append(this.directiveNodeContent)
      }

      this.openScrollbarSlider()
      this.resizeScrollObserver(scrollbarContent)
      this.mutateScrollObserver(scrollbarContent)
      this.$emit('scroll-target', scrollbarContent)
    },

    openScrollbarSlider() {
      let prevOffsetHeight = -1
      this.isVisibleSlider = false

      this.intervalSliderId = setInterval(
        throttle(() => {
          if (
            prevOffsetHeight !== -1 &&
            this.sizes.offsetHeight === prevOffsetHeight
          ) {
            this.isVisibleSlider = true
            clearInterval(this.intervalSliderId)
          }

          prevOffsetHeight = this.sizes.offsetHeight
        }, 80)
      )
    },

    scrollEvent(event) {
      const { target } = event

      this.$emit('scroll', event)
      this.emitScrollToTop(target)
      this.emitScrollToLeft(target)
      this.emitScrollToDown(target)
      this.emitScrollToRight(target)

      this.sizes.scrollTop = target.scrollTop
      this.sizes.scrollLeft = target.scrollLeft

      this.calculateScrollbarSliderOffset()
    },

    dragScrollStart(event, side) {
      this.currentSide = side
      this.isCapturedScroll = true
      document.body.style.userSelect = 'none'
      document.body.style.cursor = 'grabbing'

      const { clientY, clientX } = event
      const clientSide = this.isVerticalSide ? clientY : clientX
      const calculatedScroll = this.isVerticalSide
        ? this.calculatedScrollTop
        : this.calculatedScrollLeft

      this[`startDragPoint${side}`] = clientSide - calculatedScroll
    },

    dragScrollMove(event) {
      if (!this.isCapturedScroll) {
        return
      }

      const { clientY, clientX } = event
      const {
        offsetWidth,
        scrollWidth,
        offsetHeight,
        scrollHeight,
        sliderContentWidth,
        sliderContentHeight,
      } = this.sizes
      const clientSide = this.isVerticalSide ? clientY : clientX
      const scrollSize = this.isVerticalSide ? scrollHeight : scrollWidth
      const offsetSize = this.isVerticalSide ? offsetHeight : offsetWidth
      const sliderSize = this.isVerticalSide
        ? this.sliderHeight
        : this.sliderWidth
      const scrollBarSize = this.isVerticalSide
        ? sliderContentHeight
        : sliderContentWidth
      const startDragPoint = this[`startDragPoint${this.currentSide}`]

      let scrollOffset = clientSide - startDragPoint

      if (scrollOffset < 0) {
        scrollOffset = 0
      }

      if (scrollOffset + sliderSize > scrollBarSize) {
        scrollOffset = scrollBarSize - sliderSize
      }

      const scrollbarContent = this.$refs['scrollbar-content']
      const scrollPosition = this.isVerticalSide ? 'Top' : 'Left'

      scrollbarContent[`scroll${scrollPosition}`] =
        scrollOffset *
        ((scrollSize - offsetSize) / (scrollBarSize - sliderSize))
    },

    dragScrollEnd() {
      this.isCapturedScroll = false
      document.body.style.cursor = 'auto'
      document.body.style.userSelect = 'auto'
    },

    setScrollbarSizes() {
      const primaryTime = Date.now() + 100

      clearInterval(this.intervalMutateObserverId)
      this.intervalMutateObserverId = setInterval(() => {
        if (Date.now() > primaryTime) {
          clearInterval(this.intervalMutateObserverId)
        }

        this.setScrollbarSize()
        this.setScrollbarContentSize()
        this.setScrollbarSliderContentSize()
        this.calculateScrollbarSliderOffset()
      })
    },

    setScrollbarSize() {
      const scrollbar = this.$refs['scrollbar']

      this.sizes.offsetWidth = scrollbar?.offsetWidth ?? 0
      this.sizes.offsetHeight = scrollbar?.offsetHeight ?? 0
    },

    setScrollbarContentSize() {
      const scrollbarContent = this.$refs['scrollbar-content']

      this.sizes.scrollWidth = scrollbarContent?.scrollWidth ?? 0
      this.sizes.scrollHeight = scrollbarContent?.scrollHeight ?? 0
    },

    setScrollbarSliderContentSize() {
      const scrollbarSliderContentX = this.$refs['scrollbar-slider-content-x']
      const scrollbarSliderContentY = this.$refs['scrollbar-slider-content-y']

      this.sizes.sliderContentWidth = scrollbarSliderContentX?.offsetWidth ?? 0
      this.sizes.sliderContentHeight =
        scrollbarSliderContentY?.offsetHeight ?? 0
    },

    calculateScrollbarSliderOffset() {
      const { sliderContentWidth, sliderContentHeight } = this.sizes

      this.calculatedScrollTop = Math.round(
        this.scrollPercentageFromTop * (sliderContentHeight - this.sliderHeight)
      )

      this.calculatedScrollLeft = Math.round(
        this.scrollPercentageFromLeft * (sliderContentWidth - this.sliderWidth)
      )
    },

    registerEvents() {
      window.addEventListener('mouseup', this.dragScrollEnd)
      window.addEventListener('mousemove', this.dragScrollMove)
    },

    removeEvents() {
      window.removeEventListener('mouseup', this.dragScrollEnd)
      window.removeEventListener('mousemove', this.dragScrollMove)
    },

    resizeScrollObserver(node) {
      const observer = new ResizeObserver(this.setScrollbarSizes)

      observer.observe(node)
      this.$on('hook:destroyed', () => observer.disconnect())
    },

    mutateScrollObserver(node) {
      const config = {
        subtree: true,
        childList: true,
        attributes: true,
        characterData: true,
      }

      const observer = new MutationObserver(this.setScrollbarSizes)

      observer.observe(node, config)
      this.$on('hook:destroyed', () => observer.disconnect())
    },

    emitScrollToTop({ scrollTop, scrollLeft }) {
      if (scrollLeft !== this.sizes.scrollLeft) {
        return
      }

      if (this.isScrollTop) {
        this.isScrollTop = false
        this.$emit('is-scroll-top', this.isScrollTop)

        return
      }

      this.isScrollTop = scrollTop === 0
      this.$emit('is-scroll-top', this.isScrollTop)
    },

    emitScrollToLeft({ scrollTop, scrollLeft }) {
      if (scrollTop !== this.sizes.scrollTop) {
        return
      }

      if (this.isScrollLeft) {
        this.isScrollLeft = false
        this.$emit('is-scroll-left', this.isScrollLeft)

        return
      }

      this.isScrollLeft = scrollLeft === 0
      this.$emit('is-scroll-left', this.isScrollLeft)
    },

    emitScrollToDown({ scrollTop, scrollLeft, scrollHeight, clientHeight }) {
      if (scrollLeft !== this.sizes.scrollLeft) {
        return
      }

      if (this.isScrollDown) {
        this.isScrollDown = false
        this.$emit('is-scroll-down', this.isScrollDown)

        return
      }

      const sliderOffset = Math.min(
        scrollHeight,
        Math.ceil(scrollTop + clientHeight + window.devicePixelRatio)
      )

      this.isScrollDown = scrollHeight === sliderOffset
      this.$emit('is-scroll-down', this.isScrollDown)
    },

    emitScrollToRight({ scrollTop, scrollLeft, scrollWidth, clientWidth }) {
      if (scrollTop !== this.sizes.scrollTop) {
        return
      }

      if (this.isScrollRight) {
        this.isScrollRight = false
        this.$emit('is-scroll-right', this.isScrollRight)

        return
      }

      const sliderOffset = Math.min(
        scrollWidth,
        Math.ceil(scrollLeft + clientWidth + window.devicePixelRatio)
      )

      this.isScrollRight = scrollWidth === sliderOffset
      this.$emit('is-scroll-right', this.isScrollRight)
    },
  },
}
</script>

<style lang="scss">
$scrollTop: var(--scroll-top);
$scrollLeft: var(--scroll-left);

$scrollbarWidth: var(--scrollbar-width);
$scrollbarHeight: var(--scrollbar-height);

$sliderSize: var(--slider-size);
$sliderWidth: var(--slider-width);
$sliderHeight: var(--slider-height);
$sliderVisibility: var(--slider-visibility);
$sliderBorderRadius: var(--slider-border-radius);
$sliderContainerSize: var(--slider-container-size);
$sliderContainerWidth: var(--slider-container-width);
$sliderContainerHeight: var(--slider-container-height);

.ui-scrollbar {
  width: $scrollbarWidth;
  height: $scrollbarHeight;

  overflow: hidden;
  position: relative;
}

.ui-scrollbar-content {
  width: 100%;
  height: 100%;

  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ui-scrollbar-slider-container {
  display: flex;
  position: absolute;
  z-index: 1;
  transition: opacity 0.4s;
  opacity: $sliderVisibility;

  &.slider-container-x {
    width: calc(100% - $sliderContainerWidth);
    height: $sliderContainerSize;
    padding: 0 2px;

    align-items: center;

    left: 0;
    bottom: 0;
  }

  &.slider-container-y {
    width: $sliderContainerSize;
    height: calc(100% - $sliderContainerHeight);
    padding: 2px 0;

    justify-content: center;

    top: 0;
    right: 0;
  }
}

.ui-scrollbar-slider-content {
  &.slider-content-x {
    width: 100%;
  }

  &.slider-content-y {
    height: 100%;
  }
}

.ui-scrollbar-slider-item {
  border-radius: $sliderBorderRadius;
  background-color: $av-fixed-lighter;
  transition: width, height, background-color 0.2s;

  &:hover,
  &:active {
    background-color: $av-fixed-light;
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  &.slider-item-x {
    width: $sliderWidth;
    height: $sliderSize;

    position: relative;
    left: $scrollLeft;
    z-index: 1;

    opacity: 0;
    transition: opacity 0.2s ease-in;

    &.visible {
      opacity: 1;
    }

    &:hover,
    &:active {
      height: calc($sliderContainerSize / 1.4);
    }
  }

  &.slider-item-y {
    width: $sliderSize;
    height: $sliderHeight;

    position: relative;
    top: $scrollTop;
    z-index: 1;

    opacity: 0;
    transition: opacity 0.2s ease-in;

    &.visible {
      opacity: 1;
    }

    &:hover,
    &:active {
      width: calc($sliderContainerSize / 1.4);
    }
  }
}
</style>
