import type { MutationTree } from 'vuex'
import type {
  TWrMapMeta,
  TWrBackupInfo,
  TWrBackupVersion,
  TWrMapContentItems,
  TWrContentItemResponse,
} from '@/api/types'
import type {
  ArchiveState,
  IDirectoryTargetBackupMeta,
  IDirectoryTargetArchiveOperation,
  IDirectoryTargetArchiveOperationUpdate,
} from '@/store/modules/client/archive/types'

type ArchiveTree = MutationTree<ArchiveState>

import Vue from 'vue'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: ArchiveTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_VIEW
   * ? Установить текущие вид архивов
   *
   * @param {ArchiveState} state data
   * @param {string} view имя выбранной архивов
   * @returns {void}
   */
  SET_CURRENT_VIEW: (state: ArchiveState, view: string): void => {
    state.currentView = view
  },

  /**
   * SET_CURRENT_ARCHIVE_NAME
   * ? Установить текущие имя архива
   *
   * @param {ArchiveState} state data
   * @param {string} name имя выбранной архива
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_NAME: (state: ArchiveState, name: string): void => {
    state.currentArchiveName = name
  },

  /**
   * SET_CURRENT_ARCHIVE
   * ? Установить текущий архив
   *
   * @param {ArchiveState} state data
   * @param {TWrBackupInfo | null} archive текущий архив
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE: (
    state: ArchiveState,
    archive: TWrBackupInfo | null
  ): void => {
    state.currentArchive = archive
  },

  /**
   * SET_CURRENT_ARCHIVE_DIRECTORY_ITEM
   * ? Установить данные текущей директории
   *
   * @param {ArchiveState} state data
   * @param {TWrContentItemResponse} item текущая резервная копия
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_DIRECTORY_ITEM: (
    state: ArchiveState,
    item: TWrContentItemResponse
  ): void => {
    state.currentArchiveDirectoryItem = item
  },

  /**
   * SET_CURRENT_FOLDER
   * ? Установить текущую директорию (папку)
   *
   * @param {ArchiveState} state data
   * @param {string} folder текущая директория (папка)
   * @returns {void}
   */
  SET_CURRENT_FOLDER: (state: ArchiveState, folder: string): void => {
    state.currentFolder = folder
  },

  /**
   * SET_CURRENT_ARCHIVE_VERSION
   * ? Установить текущую версию архива
   *
   * @param {ArchiveState} state data
   * @param { TWrBackupVersion} version текущая версия архива
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_VERSION: (
    state: ArchiveState,
    version: TWrBackupVersion
  ): void => {
    state.currentArchiveVersion = version
  },

  /**
   * SET_CURRENT_ARCHIVE_VERSION_LIST
   * ? Установить список версий архива
   *
   * @param {ArchiveState} state data
   * @param {TWrBackupVersion[]} versions список версий текущего архива
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_VERSION_LIST: (
    state: ArchiveState,
    versions: TWrBackupVersion[]
  ): void => {
    state.currentArchiveVersionList = versions
  },

  /**
   * SET_MAP_ARCHIVE_DIRECTORY
   * ? Установить директорию архива
   *
   * @param {ArchiveState} state data
   * @param {TWrMapContentItems} data данные операции архива
   * @returns {void}
   */
  SET_MAP_ARCHIVE_DIRECTORY: (
    state: ArchiveState,
    data: TWrMapContentItems
  ): void => {
    state.mapArchiveData = { ...state.mapArchiveData, ...data }
  },

  /**
   * UPDATE_MAP_ARCHIVE_DIRECTORY
   * ? Обновить выбранную директорию архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryTargetArchiveOperation} data данные по содержимому архивов
   * @param {string} data.path путь запроса
   * @param {TWrBackupInfo[]} data.operation выбранная директория
   * @returns {void}
   */
  UPDATE_MAP_ARCHIVE_DIRECTORY: (
    state: ArchiveState,
    { path, operation }: IDirectoryTargetArchiveOperation
  ): void => {
    Vue.set(state.mapArchiveData, path, operation)
  },

  /**
   * UPDATE_ARCHIVE_CACHED_OPERATION_ID
   * ? Обновить закэшированный идентификатор операции архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryTargetArchiveOperationUpdate} options опции обновления операции
   * @param {string} options.path путь файла
   * @param {string} options.operationId идентификатор операции
   * @returns {void}
   */
  UPDATE_ARCHIVE_CACHED_OPERATION_ID: (
    state: ArchiveState,
    { path, operationId }: IDirectoryTargetArchiveOperationUpdate
  ): void => {
    if (!(path in state.mapArchiveData)) {
      return
    }

    state.mapArchiveData[path].id = operationId
  },

  /**
   * SET_MAP_ARCHIVE_DIRECTORY_META
   * ? Установить дерево мета данных архива
   *
   * @param {ArchiveState} state data
   * @param {TWrMapMeta} meta мета данные архива
   * @returns {void}
   */
  SET_MAP_ARCHIVE_DIRECTORY_META: (
    state: ArchiveState,
    meta: TWrMapMeta
  ): void => {
    state.mapArchiveMeta = { ...state.mapArchiveMeta, ...meta }
  },

  /**
   * UPDATE_MAP_ARCHIVE_DIRECTORY_META
   * ? Обновить мета данные выбранного архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryTargetArchiveOperation} options мета данные по содержимому архивов
   * @returns {void}
   */
  UPDATE_MAP_ARCHIVE_DIRECTORY_META: (
    state: ArchiveState,
    { path, meta }: IDirectoryTargetBackupMeta
  ): void => {
    Vue.set(state.mapArchiveMeta, path, meta)
  },

  /**
   * SET_DIRECTORY_SOURCE_PATH
   * ? Установить список директорий архива
   *
   * @param {ArchiveState} state data
   * @param {string[]} directories список директорий архива
   * @returns {void}
   */
  SET_DIRECTORY_SOURCE_PATH: (
    state: ArchiveState,
    directories: string[]
  ): void => {
    state.archiveDirectorySourcePath = directories
  },

  /**
   * ADD_DIRECTORY_SOURCE_PATH
   * ? Добавить директорию архива
   *
   * @param {ArchiveState} state data
   * @param {string} directoryName выбранная директория архива
   * @returns {void}
   */
  ADD_DIRECTORY_SOURCE_PATH: (
    state: ArchiveState,
    directoryName: string
  ): void => {
    state.archiveDirectorySourcePath.push(directoryName)
  },

  /**
   * DELETE_DIRECTORY_SOURCE_PATH
   * ? Удалить часть пути архива
   *
   * @param {ArchiveState} state data
   * @param {number} directoryIndex индекс выбранной директории архива
   * @returns {void}
   */
  DELETE_DIRECTORY_SOURCE_PATH: (state: ArchiveState, directoryIndex): void => {
    state.archiveDirectorySourcePath = state.archiveDirectorySourcePath.slice(
      0,
      directoryIndex
    )
  },

  /**
   * CLEAR_ARCHIVE_STATE
   * ? Очистить состояние содержимого архива
   *
   * @param {ArchiveState} state data
   * @returns {void}
   */
  CLEAR_ARCHIVE_STATE: (state: ArchiveState): void => {
    state.currentArchive = null
    state.currentArchiveVersion = null
    state.archiveDirectorySourcePath = []
    state.currentArchiveVersionList = []
  },
}
