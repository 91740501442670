import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  OrderResponse,
  PostOrderRequest,
  GenLicensesRequest,
  AssignLicensesRequest,
} from '@/api/types'
import type {
  LicensesState,
  GenLicensesPayload,
  ILicensesLocalPayload,
  ICampaignsLocalPayload,
  IExporTLicensesLocalPayload,
} from '@/store/modules/admin/licenses/types'

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import { exportToXLSX } from '@/helpers/xlsx'
import hasPermission from '@/helpers/permissions'
import { replaceRouteQuery } from '@/helpers/router'
import { TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { getItemStorage, setItemStorage } from '@/helpers/local-storage'

type LicensesTree = ActionTree<LicensesState, RootState>
type LicensesContext = ActionContext<LicensesState, RootState>

const { TABLE_REPORT_LICENSES, LIST_SERVICE_CAMPAIGNS } = TABLE_KEY

export const actions: LicensesTree = {
  /**
   * fetchReportLicenses
   * ? Извлечь списки назначенных и свободных лицензий
   *
   * @param {LicensesContext} ctx context
   * @returns {Promise<void>} без данных
   */
  fetchReportLicenses: ({
    commit,
    dispatch,
  }: LicensesContext): Promise<void> => {
    const { tabs } = getItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES]) ?? {}

    commit('SET_LOADING_PROCESS', { loading: true, name: 'report-licenses' })

    return dispatch(
      tabs?.license_type === 'available-licenses'
        ? 'fetchReportAvailableLicenses'
        : 'fetchReportAssignedLicenses'
    ).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'report-licenses',
      })
    })
  },

  /**
   * fetchReportAssignedLicenses
   * ? Извлечь список назначенных лицензий
   *
   * @param {LicensesContext} ctx context
   * @param {ILicensesLocalPayload} payload параметры конфигурации запроса
   * @returns {Promise<void>}
   */
  fetchReportAssignedLicenses: async (
    { state, commit, dispatch, rootGetters }: LicensesContext,
    payload: ILicensesLocalPayload = {}
  ): Promise<void> => {
    const sessionId =
      rootGetters['reportSession/assignedLicensesSession'].id ||
      (await dispatch(
        'reportSession/createSession',
        EReportType.ASSIGNED_LICENSES,
        { root: true }
      ))
    const metaState = state.currentAssignedLicensesMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_LICENSES,
      payload?.meta ?? {},
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = {
      ...paging,
      ...sort,
      ...search,
      ...filters,
    }
    const licensesPayload = { ...combinedMetaList, session_id: sessionId }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES], {
      ...currentMetaGroups,
      tabs: metaState.tabs,
    })

    commit('SET_ASSIGNED_LICENSES_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'assigned-licenses' })

    return api.report
      .getAssignedLicenses(licensesPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.license_id ?? '')
        const licenses = searchValue.length === 1 ? [] : data

        commit('SET_ASSIGNED_LICENSES', licenses)
        commit('SET_ASSIGNED_LICENSES_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })

        replaceRouteQuery({ ...metaState.tabs, ...combinedMetaList })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'assigned-licenses',
        })
      })
  },

  /**
   * fetchReportAvailableLicenses
   * ? Извлечь список свободных лицензий
   *
   * @param {LicensesContext} ctx context
   * @param {ILicensesLocalPayload} payload параметры конфигурации запроса
   * @returns {Promise<void>}
   */
  fetchReportAvailableLicenses: async (
    { state, commit, dispatch, rootGetters }: LicensesContext,
    payload: ILicensesLocalPayload = {}
  ): Promise<void> => {
    if (!hasPermission('GET_REPORT_V1_AVAILABLE_LICENSES')) {
      return Promise.resolve()
    }

    const sessionId =
      rootGetters['reportSession/availableLicensesSession'].id ||
      (await dispatch(
        'reportSession/createSession',
        EReportType.AVAILABLE_LICENSES,
        { root: true }
      ))
    const metaState = state.currentAvailableLicensesMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_LICENSES,
      payload?.meta ?? {},
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = {
      ...paging,
      ...sort,
      ...search,
      ...filters,
    }
    const licensesPayload = { ...combinedMetaList, session_id: sessionId }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES], {
      ...currentMetaGroups,
      tabs: metaState.tabs,
    })

    commit('SET_AVAILABLE_LICENSES_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'available-licenses' })

    return api.report
      .getAvailableLicenses(licensesPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.license_id ?? '')
        const licenses = searchValue.length === 1 ? [] : data

        commit('SET_AVAILABLE_LICENSES', licenses)
        commit('SET_AVAILABLE_LICENSES_CURRENT_META', { total: meta.total })

        replaceRouteQuery({ ...metaState.tabs, ...combinedMetaList })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'available-licenses',
        })
      })
  },

  /**
   * generateLicenses
   * ? Сгенерировать новые, свободные лицензии
   *
   * @param {LicensesContext} ctx context
   * @param {GenLicensesPayload} payload параметры генерации лицензии
   * @returns {Promise<void>}
   */
  generateLicenses: (
    { commit, dispatch }: LicensesContext,
    payload: GenLicensesPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'generate-license' })

    const orderPayload: PostOrderRequest = {
      type: 'orders.license.new',
      campaign_id: payload.campaign_id,
    }

    return api.lk
      .generateOrdersCampaign(orderPayload)
      .then((order: OrderResponse) => {
        const { sku, amount } = payload
        const licensePayload: GenLicensesRequest = {
          sku,
          amount: amount,
          for_transfer: true,
          order_id: order.id,
          pool_id: 'campaign',
          output_format: 'json',
        }

        return api.lk
          .generateLicenses(licensePayload)
          .then((licenseResponse) => {
            return api.lk.getCurrentSku(sku).then((skuResponse) => {
              commit('SET_GENERATED_KEYS', {
                amount,
                skuId: sku,
                date: new Date(),
                sku: skuResponse,
                license: licenseResponse,
              })
              commit('SET_AVAILABLE_LICENSES_CURRENT_META', null)
              commit(
                'reportSession/DELETE_REPORT_SESSION_ID',
                EReportType.AVAILABLE_LICENSES,
                { root: true }
              )
            })
          })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'generate-license',
        })
        dispatch('fetchReportAvailableLicenses')
      })
  },

  /**
   * fetchSkus
   * ? Извлечь список всех sku
   *
   * @param {LicensesContext} ctx context
   * @returns {Promise<void>}
   */
  fetchSkus: ({ commit }: LicensesContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'skus' })

    return api.lk
      .getListSku()
      .then((sku) => commit('SET_SKUS', sku))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'skus',
        })
      })
  },

  /**
   * fetchCampaigns
   * ? Извлечь список кампаний
   *
   * @param {LicensesContext} ctx context
   * @param {ICampaignsLocalPayload} payload параметры конфигурации запроса кампаний
   * @returns {Promise<void>}
   */

  fetchCampaigns: async (
    { state, commit }: LicensesContext,
    payload: ICampaignsLocalPayload
  ): Promise<void> => {
    const { installType = 'add' } = payload
    const commitMeta = installType === 'add' ? 'ADD_CAMPAIGNS' : 'SET_CAMPAIGNS'
    const metaState = state.currentLicenseCampaignsMeta
    const currentMetaGroups = getMetaGroups(
      LIST_SERVICE_CAMPAIGNS,
      payload.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const campaignsPayload = { ...combinedMetaList }

    commit('SET_LICENSE_CAMPAIGNS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'campaigns' })

    return api.lk
      .getCampaigns(campaignsPayload)
      .then(({ data, meta }) => {
        commit(commitMeta, data)
        commit('SET_LICENSE_CAMPAIGNS_CURRENT_META', {
          page: meta.page,
          total: meta.total,
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'campaigns',
        })
      })
  },

  /**
   * assignLicenses
   * ? Назначить лицензию
   *
   * @param {LicensesContext} ctx context
   * @param {AssignLicensesRequest} payload context
   * @returns {Promise<void>} без данных
   */
  assignLicenses: (
    { commit }: LicensesContext,
    payload: AssignLicensesRequest
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'assign-license' })

    return api.lk.assignLicenses(payload).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'assign-license',
      })
    })
  },

  /**
   * exportAssignedLicenses
   * ? Экспортировать назначенных лицензии в файл
   *
   * @param {IExporTLicensesLocalPayload} payload промежуточные данные
   * @param {LicensesContext} param0 context
   * @returns {Promise<void>}
   */
  exportAssignedLicenses: (
    { commit, rootGetters }: LicensesContext,
    { type, filename }: IExporTLicensesLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const assignedLicensesPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/assignedLicensesSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-licenses' })

    return api.report
      .exportAssignedLicenses(assignedLicensesPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'export-licenses',
        })
      })
  },

  /**
   * exportAvailableLicenses
   * ? Экспортировать свободных лицензии в файл
   *
   * @param {IExporTLicensesLocalPayload} payload промежуточные данные
   * @param {LicensesContext} param0 context
   * @returns {Promise<void>}
   */
  exportAvailableLicenses: (
    { commit, rootGetters }: LicensesContext,
    { type, filename }: IExporTLicensesLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const availableLicensesPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/availableLicensesSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-licenses' })

    return api.report
      .exportAvailableLicenses(availableLicensesPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'export-licenses',
        })
      })
  },
}
