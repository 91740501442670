import './loader.js'
import Vue from 'vue'
import store from '@/store'

interface MetricOptionPayload {
  goal?: string
  event?: string
  pageType?: string
}

interface MetricDataLayerPayload extends MetricOptionPayload {
  Goal?: string
  userAuth: number
  userId: number | string
}

declare global {
  interface Window {
    dataLayer: MetricDataLayerPayload[]
  }
}

const USE_METRICS = JSON.parse(process.env.VUE_APP_USE_METRICS as string)

/**
 * ? metricPush
 * Отправить данные для метрики
 * В себе всегда содержится актуальные состояние аутентификации
 *
 * @param {MetricOptionPayload} options опции для метрики
 * @param {string} options.goal триггер действия
 * @param {string} options.event название события
 * @param {string} options.pageType текущая страница
 * @returns {void}
 */
Vue.prototype.$metricPush = (options: MetricOptionPayload = {}): void => {
  if (!USE_METRICS) {
    return
  }

  const account = store.getters['account/metricAccountProperties']
  const { event, goal } = options
  // Изменяю название свойства "goal" на "Goal" как этого требует отдел аналитики
  const additional = goal ? { event, Goal: goal } : options

  window?.dataLayer.push({
    ...account,
    ...additional,
  })
}
