<template>
  <div class="vpc-wrapper">
    <transition
      mode="out-in"
      name="vpc-layout"
    >
      <component :is="layout">
        <router-view />
      </component>
    </transition>

    <vpc-modal-common-error v-model="userCommonError" />
  </div>
</template>

<script>
import { initQueryParams } from '@/helpers'
import { mapGetters, mapMutations } from 'vuex'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import VpcModalCommonError from '@/components/modals/vpc-modal-common-error.vue'

export default {
  name: 'VpcApp',

  components: {
    VpcModalCommonError,
    VpcAuthLayout: () => import('@/layouts/vpc-auth.vue'),
    VpcUserLayout: () => import('@/layouts/vpc-client.vue'),
    VpcAdminLayout: () => import('@/layouts/vpc-admin.vue'),
    VpcBusinessLayout: () => import('@/layouts/vpc-business.vue'),
  },

  computed: {
    ...mapGetters({
      homePage: 'nav/homePage',
      serverError: 'serverError',
      isUserLoggedIn: 'account/isUserLoggedIn',
      isAccountDeleted: 'account/isAccountDeleted',
      isRoleBusinessUser: 'account/isRoleBusinessUser',
      visibleUserCommonError: 'visibleUserCommonError',
      visibleAdminCommonError: 'visibleAdminCommonError',
    }),

    userCommonError: {
      get() {
        return this.visibleUserCommonError
      },

      set(value) {
        this.closeUserCommonError(value)
      },
    },

    layout() {
      const guardAuthRequired = this.$route.meta.guardAuthRequired

      if (!guardAuthRequired) {
        return 'VpcAuthLayout'
      }

      if (this.isRoleBusinessUser) {
        return 'VpcBusinessLayout'
      }

      return this.$hasPermission('VIEW_LAYOUT_ADMINISTRATION')
        ? 'VpcAdminLayout'
        : 'VpcUserLayout'
    },
  },

  watch: {
    $route(to) {
      this.setCurrentRoute(to)
      this.$metricPush({ pageType: to.name })
    },

    visibleAdminCommonError() {
      if (!this.visibleAdminCommonError || this.serverError === null) {
        return
      }

      const { code, domain, context } = this.serverError

      this.$notify({
        type: 'error',
        title: `Domain "${domain}"`,
        duration: 0,
        message: { code, context },
      })

      this.closeAdminCommonError()
    },

    isUserLoggedIn() {
      this.$metricPush({
        event: 'Cyberprotect',
        goal: 'success_login_account',
      })

      if (!this.isUserLoggedIn) {
        const page = this.isAccountDeleted ? 'deleted' : 'login'
        this.$router.push({ name: page })

        return
      }

      const { page, path } = initQueryParams('return_to')
      const currentPath = this.homePage.pages.includes(page)
        ? path
        : this.homePage.path

      this.routerPush(currentPath)
    },
  },

  methods: {
    ...mapMutations({
      setCurrentRoute: 'nav/SET_CURRENT_ROUTE',
      closeUserCommonError: 'CLOSE_USER_COMMON_ERROR',
      closeAdminCommonError: 'CLOSE_ADMIN_COMMON_ERROR',
    }),

    routerPush(path) {
      this.$router.push({ path }).catch((error) => {
        if (isNavigationFailure(error, NavigationFailureType.redirected)) {
          return Promise.resolve()
        }

        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles';

.vpc-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $av-solid-brand-lightest;
}

.vpc-layout-enter-active {
  &.vpc-auth-layout {
    animation: auth-layout-enter 0.4s ease;
    @keyframes auth-layout-enter {
      0% {
        opacity: 0;
        transform: translateY(-100px);
      }
    }
  }
  &.vpc-account-layout {
    animation: account-layout-enter 0.4s ease;
    @keyframes account-layout-enter {
      0% {
        opacity: 0;
      }
    }
  }
}

.vpc-layout-leave-active {
  &.vpc-auth-layout {
    animation: auth-layout-leave 0.3s ease-out;

    @keyframes auth-layout-leave {
      100% {
        opacity: 0;
        transform: scale(0);
      }
    }
  }
  &.vpc-account-layout {
    animation: account-layout-leave 0.3s ease-out;

    @keyframes account-layout-leave {
      100% {
        opacity: 0;
      }
    }
  }
}
</style>
