import type { TCountry } from '@/api/types'

import IMask from 'imask'
import { COUNTRIES } from '@/config/constants'
import { getFormattedPhone } from '.'

type TPhoneConfig = {
  code: string
  mask: string
  length: number
  country: TCountry
}

const PHONE_CONFIG: TPhoneConfig[] = [
  {
    length: 11,
    code: '+7',
    country: COUNTRIES.RU,
    mask: '{+7} (000) 000-00-00',
  },
  {
    length: 12,
    code: '+375',
    country: COUNTRIES.BY,
    mask: '{+375} (00) 000-00-00',
  },
]

/**
 * ? getConfigPhoneByCountry
 * Получить конфигурацию страны по короткому имени
 *
 * @param {TCountry} name короткое имя страны
 * @returns {TPhoneConfig | null} объект с конфигурацией страны
 */
export const getConfigPhoneByCountry = (
  name: TCountry
): TPhoneConfig | null => {
  return PHONE_CONFIG?.find((item) => item.country === name) ?? null
}

/**
 * ? getConfigPhoneByPhone
 * Получить конфигурацию страны по телефону
 *
 * @param {string} phone номер телефона
 * @param {TCountry} defaultCountryName дефолтное имя страны
 * @returns {TPhoneConfig | null} объект с конфигурацией страны
 */
export const getConfigPhoneByPhone = (
  phone: string,
  defaultCountryName?: TCountry
): TPhoneConfig | null => {
  const currentPhoneCountry = PHONE_CONFIG.find(({ code }) => {
    if (!phone) {
      return false
    }

    const formattedPhone = getFormattedPhone(phone)
    const pattern = `\\${formattedPhone.slice(0, code.length)}`
    const reg = new RegExp(pattern)

    return reg.test(code)
  })

  if (currentPhoneCountry) {
    return currentPhoneCountry
  }

  if (defaultCountryName) {
    return getConfigPhoneByCountry(defaultCountryName)
  }

  return null
}

/**
 * ? getMaskedPhone
 * Получить отформатированный телефон в маске
 *
 * @param {string} phone номер телефона
 * @returns {string} отформатированный телефон в маске
 */
export const getMaskedPhone = (phone: string): string => {
  if (!phone) {
    return ''
  }

  const formattedPhone = getFormattedPhone(phone)
  const currentPhoneCountry = getConfigPhoneByPhone(formattedPhone)

  if (!currentPhoneCountry) {
    return ''
  }

  const { mask } = currentPhoneCountry
  const masked = IMask.createMask({ mask })

  masked.resolve(formattedPhone)

  return masked.value
}
