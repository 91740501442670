import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TWrMapMeta,
  TWrLocalMeta,
  TWrPathParams,
  TWrContentItem,
  TWrContentItems,
  TWrBackupVersion,
  TWrMapContentItems,
  TWrBackupInfoFormatted,
  TWrContentItemResponse,
} from '@/api/types'
import { ArchiveState } from '@/store/modules/client/archive/types'

type ArchiveTree = GetterTree<ArchiveState, RootState>

import cloneDeep from 'lodash/cloneDeep'
import { getWrIcons } from '@/helpers/wr-icons'
import { getLastUpdatedDate, getCurrentVersions } from '@/helpers/storage'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

/**
 * getFormattedArchiveItem
 * ? Получить сформированные данные элемента архива
 *
 * @param {TWrContentItemResponse} directoryItem данные элемента архива
 * @returns {TWrContentItem} сформированные данные элемента архива
 */
const getFormattedArchiveItem = (
  directoryItem: TWrContentItemResponse
): TWrContentItem => {
  const { size, type, subtype, updated_at } = directoryItem

  return {
    ...directoryItem,
    icon: getWrIcons(subtype ?? type),
    formatSize: getFormattedSize(size),
    formatUpdatedItemDate: getFormattedDate(updated_at),
    formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
  }
}

export const getters: ArchiveTree = {
  /**
   * mapArchiveData
   * ? Объект архивов со списком директорий/файлов, статусом и id операции
   *
   * @param {ArchiveState} state data
   * @returns {Partial<TWrMapContentItems>} объект архивов со списком директорий/файлов, статусом и id операции
   */
  mapArchiveData: (state: ArchiveState): Partial<TWrMapContentItems> => {
    return state.mapArchiveData
  },

  /**
   * mapArchiveMeta
   * ? Объект архивов с мета данными
   *
   * @param {ArchiveState} state data
   * @returns {Partial<TWrMapMeta>} объект архивов с мета данными
   */
  mapArchiveMeta: (state: ArchiveState): Partial<TWrMapMeta> => {
    return state.mapArchiveMeta
  },

  /**
   * defaultArchiveMeta
   * ? Дефолтные мета данные архива
   *
   * @param {ArchiveState} state data
   * @returns {TWrLocalMeta} дефолтные мета данные архива
   */
  defaultArchiveMeta: (state: ArchiveState): TWrLocalMeta => {
    return state.defaultArchiveMeta
  },

  /**
   * archiveDirectorySourcePath
   * ? Исходный путь директорий элементов архивов
   *
   * @param {ArchiveState} state data
   * @returns {string[]} исходный путь директорий элементов архивов
   */
  archiveDirectorySourcePath: (state: ArchiveState): string[] => {
    return state.archiveDirectorySourcePath
  },

  /**
   * archiveDirectoryNavigationPath
   * ? Навигационный путь директорий элементов архива (для хлебных крошек)
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {string[]} навигационный путь директорий элементов архива
   */
  archiveDirectoryNavigationPath: (state: ArchiveState, getters): string[] => {
    return [
      getters.currentArchiveDisplayName,
      ...state.archiveDirectorySourcePath,
    ]
  },

  /**
   * currentView
   * ? Текущий вид архивов
   *
   * @param {ArchiveState} state data
   * @returns {string} текущий вид архивов
   */
  currentView: (state: ArchiveState): string => {
    return state.currentView
  },

  /**
   * currentArchiveFolder
   * ? Имя текущей директории (папки) архива
   *
   * @param {ArchiveState} state data
   * @returns {string} имя текущей директории (папки) архива
   */
  currentArchiveFolder: (state: ArchiveState): string => {
    return state.currentFolder
  },

  /**
   * currentArchiveName
   * ? Имя текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {string} имя текущего архива
   */
  currentArchiveName: (state: ArchiveState): string => {
    return state.currentArchiveName
  },

  /**
   * currentArchiveDisplayName
   * ? Текущее отображаемое имя архива
   *
   * @param {ArchiveState} state data
   * @returns {string} текущее отображаемое имя архива
   */
  currentArchiveDisplayName: (state: ArchiveState): string => {
    return state.currentArchive?.display_name ?? ''
  },

  /**
   * currentArchiveVersion
   * ? Текущая версия архива
   *
   * @param {ArchiveState} state data
   * @returns {TWrBackupVersion  | null} текущая версия архива
   */
  currentArchiveVersion: (state: ArchiveState): TWrBackupVersion | null => {
    return state.currentArchiveVersion
  },

  /**
   * currentArchiveVersionName
   * ? Название текущей версия резервной копии
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {string} название текущей версия резервной копии
   */
  currentArchiveVersionName: (state: ArchiveState, getters): string => {
    return getters.currentArchiveVersion?.name ?? ''
  },

  /**
   * currentArchiveVersionList
   * ? Список версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {TWrBackupVersion[]} список версий текущего архива
   */
  currentArchiveVersionList: (state: ArchiveState): TWrBackupVersion[] => {
    return cloneDeep(state.currentArchiveVersionList).sort(
      (a: TWrBackupVersion, b: TWrBackupVersion) =>
        Number(b.name) - Number(a.name)
    )
  },

  /**
   * currentDirectoryArchiveItem
   * ? Текущий элемент директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {TWrContentItems} текущий элемент директории архива
   */
  currentDirectoryArchiveItem: (
    state: ArchiveState,
    getters
  ): TWrContentItems => {
    return (
      state.mapArchiveData[getters.currentArchivePathParams.backupPath] ?? {}
    )
  },

  /**
   * currentDirectoryArchiveMeta
   * ? Текущие мета данные директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {TWrLocalMeta} текущие мета данные директории архива
   */
  currentDirectoryArchiveMeta: (state: ArchiveState, getters): TWrLocalMeta => {
    return (
      state.mapArchiveMeta[getters.currentArchivePathParams.backupPath] ??
      state.defaultArchiveMeta
    )
  },

  /**
   * currentArchive
   * ? Содержимое текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {TWrBackupInfoFormatted | null } содержимое текущего архива
   */
  currentArchive: (state: ArchiveState): TWrBackupInfoFormatted | null => {
    if (state.currentArchive === null) {
      return null
    }

    const { size, name, subtype, updated_at, display_name, created_at } =
      state.currentArchive

    const params = { name, versions: state.currentArchiveVersionList }

    const lastUpdatedDate = getLastUpdatedDate(params)
    const versions = getCurrentVersions(params)

    return {
      ...state.currentArchive,
      icon: getWrIcons(subtype),
      formatTotalSize: getFormattedSize(size),
      formatCreatedDate: getFormattedDate(created_at),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatLastUpdatedDateTime: getFormattedDate(lastUpdatedDate, true),
      amountVersion: versions.length,
    }
  },

  /**
   * currentArchiveDirectory
   * ? Текущая (выбранная) директория архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {TWrContentItem[]} текущая (выбранная) директория архива
   */
  currentArchiveDirectory: (state: ArchiveState, getters): TWrContentItem[] => {
    const archiveItems = getters.currentDirectoryArchiveItem.items ?? []

    return archiveItems.map((archiveItem: TWrContentItemResponse) =>
      getFormattedArchiveItem(archiveItem)
    )
  },

  /**
   * currentArchiveDirectoryItem
   * ? Данные по текущей директории резервной копии
   *
   * @param {ArchiveState} state data
   * @returns {TWrContentItem | null} данные по текущей директории резервной копии
   */
  currentArchiveDirectoryItem: (state: ArchiveState): TWrContentItem | null => {
    const directoryItem = state.currentArchiveDirectoryItem

    if (directoryItem === null) {
      return null
    }

    return getFormattedArchiveItem(directoryItem)
  },

  /**
   * currentArchivePathParams
   * ? Параметры текущего пути директории
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {TWrPathParams} параметры текущего пути директории
   */
  currentArchivePathParams: (state: ArchiveState, getters): TWrPathParams => {
    const {
      archiveDirectorySourcePath,
      currentArchiveName,
      currentArchiveDisplayName,
    } = getters

    const backup = currentArchiveName
    const path = archiveDirectorySourcePath.join('/')
    const folder =
      archiveDirectorySourcePath.at(-1) ?? currentArchiveDisplayName
    const backupPath = [backup, path].filter((item) => item).join('/')

    return {
      path,
      backup,
      folder,
      backupPath,
    }
  },

  /**
   * hasArchiveVersion
   * ? Признак что версия архива существует
   *
   * @param {ArchiveState} state data
   * @returns {boolean} признак что версия архива существует
   */
  hasArchiveVersion: (state: ArchiveState): boolean => {
    return state.currentArchiveVersion !== null
  },

  /**
   * isEncryptedArchive
   * ? Признак зашифрованного архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} признак зашифрованного архива
   */
  isEncryptedArchive: (state: ArchiveState): boolean => {
    return Boolean(state.currentArchive?.encrypted)
  },

  /**
   * isCachedCurrentDirectoryArchiveItem
   * ? Признак того, что элементы архива за кешированы
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {boolean} признак того, что элементы архива за кешированы
   */
  isCachedCurrentDirectoryArchiveItem: (
    state: ArchiveState,
    getters
  ): boolean => {
    return getters.currentArchivePathParams.backupPath in state.mapArchiveData
  },

  /**
   * isLoadingCurrentArchive
   * ? Процесс загрузки текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего архива
   */
  isLoadingCurrentArchive: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchive
  },

  /**
   * isLoadingUrlArchiveImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlArchiveImage: (state: ArchiveState): boolean => {
    return state.isLoadingUrlArchiveImage
  },

  /**
   * isLoadingCurrentArchiveVersionList
   * ? Процесс загрузки списка версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки списка версий текущего архива
   */
  isLoadingCurrentArchiveVersionList: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveVersionList
  },

  /**
   * isLoadingFirstDirectoryArchiveItems
   * ? Процесс загрузки первых директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки первых директорий архива
   */
  isLoadingFirstDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingFirstDirectoryArchiveItems
  },

  /**
   * isLoadingNextDirectoryArchiveItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingNextDirectoryArchiveItems
  },

  /**
   * isLoadingCurrentArchiveFileDownload
   * ? Процесс скачивания выбранного файла архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс скачивания выбранного файла архива
   */
  isLoadingCurrentArchiveFileDownload: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveFileDownload
  },
}
