import type { AccountState, AccountModule } from '@/store/modules/account/types'

import { actions } from '@/store/modules/account/actions'
import { getters } from '@/store/modules/account/getters'
import { mutations } from '@/store/modules/account/mutations'

const state: AccountState = {
  email: '',
  password: '',
  account: null,
  branches: [],
  isAccountDeleted: false,
  isLoadingAccount: false,
  isLoadingAddBranch: false,
  isLoadingEditBranch: false,
  isLoadingDeleteBranch: false,
  isLoadingUpdateAccount: false,
  isLoadingChangePassword: false,
  isLoadingCompanyBranches: false,
  isLoadingRegisterCustomer: false,
  isLoadingRegisterBusiness: false,
  isEnabledRegisterBusiness: false,
  isLoadingNewsletterSubscription: false,
  isLoadingEnabledBusinessRegister: false,
}

export const account: AccountModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
