import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IArchivesState } from '@/store/modules/client/archives/types'
import type {
  TWrBackupInfo,
  TWrBackupVersions,
  TWrBackupLocalMeta,
  TWrBackupInfoFormatted,
} from '@/api/types'

type ArchivesTree = GetterTree<IArchivesState, RootState>

import { getCurrentVersions, getLastUpdatedDate } from '@/helpers/storage'
import { getWrIcons, ICON_TYPE_LIST } from '@/helpers/wr-icons'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchivesTree = {
  /**
   * hasArchives
   * ? Признак наличия архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} признак наличия архивов
   */
  hasArchives: (state: IArchivesState): boolean => {
    return state.archives.length > 0
  },

  /**
   * archives
   * ? Список архивов
   *
   * @param {IArchivesState} state data
   * @returns {TWrBackupInfoFormatted[]} список архивов
   */
  archives: (state: IArchivesState): TWrBackupInfoFormatted[] => {
    return state.archives.map((archive: TWrBackupInfo) => {
      const { name, size, display_name, subtype, updated_at } = archive

      const [decodedName] = decodeURIComponent(name).split('/')
      const params = { name, versions: state.versions }

      const lastUpdatedDate = getLastUpdatedDate(params)
      const versions = getCurrentVersions(params)

      return {
        ...archive,
        formatDisplayName: display_name,
        icon: getWrIcons(subtype, ICON_TYPE_LIST),
        formatUpdatedItemDate: getFormattedDate(updated_at),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
        formatTotalSize: getFormattedSize(size),
        formatName: getFormattedSimpleValue(decodedName),
        amountVersion: versions.length,
        formatCreatedDate: getFormattedDate(lastUpdatedDate),
        formatLastUpdatedDateTime: getFormattedDate(lastUpdatedDate, true),
      }
    })
  },

  /**
   * archiveListVersions
   * ? Список всех архивов со всеми версиями
   *
   * @param {IArchivesState} state data
   * @returns {TWrBackupVersions} список всех архивов со всеми версиями
   */
  archiveListVersions: (state: IArchivesState): TWrBackupVersions => {
    return state.versions
  },

  /**
   * currentArchivesMeta
   * ? Текущие мета списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {TWrBackupLocalMeta} текущие мета списка архивов
   */
  currentArchivesMeta: (state: IArchivesState): TWrBackupLocalMeta => {
    return state.currentArchivesMeta
  },

  /**
   * isLoadingArchives
   * ? Процесс загрузки списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки списка архивов
   */
  isLoadingArchives: (state: IArchivesState): boolean => {
    return state.isLoadingArchives
  },

  /**
   * isLoadingArchiveDelete
   * ? Процесс удаления архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс удаления архива
   */
  isLoadingArchiveDelete: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveDelete
  },
}
