import i18n from '@/locale'
import { setMetaTitle } from '@/helpers'
import { setQueryParams } from '@/helpers/queries'

// Инициализация метрики
import '@/tools/metric'

// Инициализация правил валидации
import '@/validations/forms'

// Установка заголовка браузера
setMetaTitle(String(i18n.t('app.meta.title')))

// Инициализация query параметров пагинации и фильтров в localeStorage
setQueryParams()
