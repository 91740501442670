import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { BackupsState } from '@/store/modules/client/backups/types'
import type {
  TWrBackupInfo,
  TWrBackupVersions,
  TWrBackupLocalMeta,
  TWrBackupInfoFormatted,
} from '@/api/types'

type BackupsTree = GetterTree<BackupsState, RootState>

import { App } from '@/main'
import { getLastUpdatedDate, getCurrentVersions } from '@/helpers/storage'
import { getWrIcons, ICON_TYPE_LIST } from '@/helpers/wr-icons'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: BackupsTree = {
  /**
   * hasBackups
   * ? Признак наличия резервных копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} признак наличия резервных копий
   */
  hasBackups: (state: BackupsState): boolean => {
    return state.backups.length > 0
  },

  /**
   * backups
   * ? Список резервных копий
   *
   * @param {BackupsState} state data
   * @returns {Array<TWrBackupInfo>} список резервных копий
   */
  backups: (state: BackupsState): TWrBackupInfoFormatted[] => {
    return state.backups.map((backup: TWrBackupInfo) => {
      const {
        name,
        size,
        device,
        subtype,
        created_at,
        updated_at,
        display_name,
      } = backup

      const params = { name, versions: state.versions }

      const lastUpdatedDate = getLastUpdatedDate(params)
      const versions = getCurrentVersions(params)

      return {
        ...backup,
        device:
          device || App.$i18n.t('page.backup.device.not-recognized').toString(),
        icon: getWrIcons(subtype, ICON_TYPE_LIST),
        formatTotalSize: getFormattedSize(size),
        amountVersion: versions.length,
        formatDisplayName: getFormattedSimpleValue(display_name),
        formatCreatedDate: getFormattedDate(created_at),
        formatUpdatedItemDate: getFormattedDate(updated_at),
        formatLastCreatedDate: getFormattedDate(lastUpdatedDate),
        formatLastUpdatedDateTime: getFormattedDate(lastUpdatedDate, true),
        formatUpdatedItemDateTime: getFormattedDate(updated_at),
      }
    })
  },

  /**
   * backupListVersions
   * ? Список всех бэкапов со всеми версиями
   *
   * @param {BackupsState} state data
   * @returns {TWrBackupVersions} список всех бэкапов со всеми версиями
   */
  backupListVersions: (state: BackupsState): TWrBackupVersions => {
    return state.versions
  },

  /**
   * currentBackupsMeta
   * ? Текущие мета списка бэкапов
   *
   * @param {BackupsState} state data
   * @returns {TWrBackupLocalMeta} текущие мета списка бэкапов
   */
  currentBackupsMeta: (state: BackupsState): TWrBackupLocalMeta => {
    return state.currentBackupsMeta
  },

  /**
   * isLoadingBackups
   * ? Процесс загрузки списка резервных копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс загрузки списка резервных копий
   */
  isLoadingBackups: (state: BackupsState): boolean => {
    return state.isLoadingBackups
  },

  /**
   * isLoadingBackupDelete
   * ? Процесс удаления резервной копий
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс удаления резервной копий
   */
  isLoadingBackupDelete: (state: BackupsState): boolean => {
    return state.isLoadingBackupDelete
  },

  /**
   * isLoadingBackupDownload
   * ? Процесс скачивания резервной копии
   *
   * @param {BackupsState} state data
   * @returns {boolean} процесс скачивания резервной копи
   */
  isLoadingBackupDownload: (state: BackupsState): boolean => {
    return state.isLoadingBackupDownload
  },

  /**
   * groupLoadingBackupVersions
   * ? Процесс множественной загрузки версий бэкапов
   *
   * @param {BackupsState} state data
   * @returns {Function} процесс множественной загрузки версий бэкапов
   */
  groupLoadingBackupVersions: (
    state: BackupsState
  ): ((name: string) => boolean) => {
    return (name) => {
      return state.groupLoadingBackupVersions[name]
    }
  },
}
