import type {
  ArchiveState,
  ArchiveModule,
} from '@/store/modules/client/archive/types'
import type { TWrLocalMeta } from '@/api/services/webrestore/types'

import { getters } from '@/store/modules/client/archive/getters'
import { actions } from '@/store/modules/client/archive/actions'
import { mutations } from '@/store/modules/client/archive/mutations'

const getArchiveMeta = (): TWrLocalMeta => {
  return {
    page: 1,
    staticLimit: 100,
    direction: 'desc',
    order_by: 'updated_at',
  }
}

const state: ArchiveState = {
  currentView: '',
  currentFolder: '',
  currentArchiveName: '',

  mapArchiveData: {},
  mapArchiveMeta: {},
  currentArchive: null,
  currentArchiveVersion: null,
  currentArchiveDirectoryItem: null,
  currentArchiveVersionList: [],
  archiveDirectorySourcePath: [],
  defaultArchiveMeta: getArchiveMeta(),
  groupLoadingCurrentArchiveFolderDownload: {},

  isLoadingCurrentArchive: false,
  isLoadingUrlArchiveImage: false,
  isLoadingNextDirectoryArchiveItems: false,
  isLoadingCurrentArchiveVersionList: false,
  isLoadingFirstDirectoryArchiveItems: false,
  isLoadingCurrentArchiveFileDownload: false,
}

export const archive: ArchiveModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
