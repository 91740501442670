import api from '@/api'
import AssignExternalLicensesModel from '@/services/AssignExternalLicensesService/AssignExternalLicensesModel'

export default class AssignExternalLicensesService {
  public static assign(model: AssignExternalLicensesModel): Promise<void> {
    const assignPayload = model.getAssignPayload()
    return (
      api.lk
        // Проверяем возможность привязки лицензии к аккаунту.
        .checkAssignExternalLicense(assignPayload)
        // Привязываем аккаунт к компании
        .then(() =>
          api.account.linkCompany(
            model.getAccountId(),
            model.getLinkCompanyPayload()
          )
        )
        // Привязываем лицензии к аккаунту
        .then(() => api.lk.assignExternalLicense(assignPayload))
    )
  }
}
