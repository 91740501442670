import type { TWrBackupVersion, TWrBackupVersions } from '@/api/types'

import sumBy from 'lodash/sumBy'

type TVersions = TWrBackupVersions | TWrBackupVersions[keyof TWrBackupVersions]

type TVersionsPayload = {
  name: string
  versions: TVersions
}

/**
 * getCurrentVersions
 * ? Получить список всех версий текущего бэкапа / архива
 *
 * @param {TVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {TWrBackupVersion[]} список всех версий текущего бэкапа / архива
 */
export const getCurrentVersions = ({
  name,
  versions,
}: TVersionsPayload): TWrBackupVersion[] => {
  return Array.isArray(versions) ? versions : versions[name] ?? []
}

/**
 * getLastVersion
 * ? Получить последнюю версию текущего бэкапа / архива
 *
 * @param {TVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {TWrBackupVersion | null} последняя версия текущего бэкапа / архива
 */
export const getLastVersion = (
  params: TVersionsPayload
): TWrBackupVersion | null => {
  const currentVersions = getCurrentVersions(params)

  if (Array.isArray(currentVersions)) {
    const numberVersionList = currentVersions
      .filter(({ name }) => name)
      .map(({ name }) => Number(name))
    const maxVersionNumber = Math.max(...numberVersionList)

    return (
      currentVersions.find(({ name }) => Number(name) === maxVersionNumber) ??
      null
    )
  }

  return null
}

/**
 * getTotalSize
 * ? Общий размер бэкапа / архива
 *
 * @param {TVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {number} общий размер
 */
export const getTotalSize = (params: TVersionsPayload): number => {
  const currentVersions = getCurrentVersions(params)

  return sumBy(currentVersions ?? [], 'size')
}

/**
 * getLastUpdatedDate
 * ? Дата последнего обновления бэкапа / архива
 *
 * @param {TVersionsPayload} params параметры
 *  @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {string} дата последнего бэкапа / архива
 */
export const getLastUpdatedDate = (params: TVersionsPayload): string => {
  return getLastVersion(params)?.updated_at ?? ''
}
