import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TWrMapMeta,
  TWrBaseItem,
  TWrLocalMeta,
  TWrContentItem,
  TWrContactData,
  TWrContentItems,
  TWrBackupVersion,
  TWrMapContentItems,
  TWrContactContentItem,
  TWrContentItemResponse,
  TWrBackupInfoFormatted,
} from '@/api/types'
import type {
  BackupState,
  TBackupPathParams,
  IWrLocaleBackupContact,
} from '@/store/modules/client/backup/types'

type BackupTree = GetterTree<BackupState, RootState>

import { App } from '@/main'
import cloneDeep from 'lodash/cloneDeep'
import { getLastUpdatedDate, getCurrentVersions } from '@/helpers/storage'
import {
  getWrIcons,
  ICON_TYPE_CONTENT,
  ICON_TYPE_CATEGORIES,
  WR_MIME_TYPE_CATEGORY,
  MOBILE_CATEGORY_BACKUPS,
} from '@/helpers/wr-icons'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

const getContact = (
  data: TWrContactData | undefined = {}
): Partial<IWrLocaleBackupContact> => {
  const phone = data?.phones?.[0] ?? null
  const email = data?.emails?.[0] ?? null

  return {
    phone,
    email,
    amountPhones: data?.phones?.length ?? 0,
    amountEmails: data?.emails?.length ?? 0,
    formatPhone: getFormattedSimpleValue(phone),
    formatEmail: getFormattedSimpleValue(email),
  }
}
/**
 * getFormattedBackupItem
 * ? Получить сформированные данные элемента бэкапа
 *
 * @param {TWrContentItemResponse} backupItem данные элемента бэкапа
 * @param {boolean} isCategoryContacts признак категории с контактами
 * @returns {TWrContentItem} сформированные данные элемента бэкапа
 */
const getFormattedBackupItem = (
  backupItem: TWrContentItemResponse,
  isCategoryContacts: boolean
): TWrContentItem => {
  const { size, type, subtype, updated_at } = backupItem
  const isContact = (content: TWrBaseItem): content is TWrContactContentItem =>
    content.type === 'file' && 'data' in content && isCategoryContacts

  const iconType =
    type === 'dir' &&
    Object.values(MOBILE_CATEGORY_BACKUPS).includes(subtype ?? '')
      ? ICON_TYPE_CATEGORIES
      : ICON_TYPE_CONTENT

  return {
    ...backupItem,
    ...(isContact(backupItem) ? getContact(backupItem.data) : {}),
    formatSize: getFormattedSize(size),
    icon: getWrIcons(subtype ?? type, iconType),
    formatUpdatedItemDate: getFormattedDate(updated_at),
    formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
  }
}

export const getters: BackupTree = {
  /**
   * mapBackupData
   * ? Объект бэкапов со списком директорий/файлов, статусом и id операции
   *
   * @param {BackupState} state data
   * @returns {TWrMapContentItems} объект бэкапов со списком директорий/файлов, статусом и id операции
   */
  mapBackupData: (state: BackupState): TWrMapContentItems => {
    return state.mapBackupData
  },

  /**
   * mapBackupMeta
   * ? Объект бэкапов с мета данными
   *
   * @param {BackupState} state data
   * @returns {Partial<TWrMapMeta>} объект бэкапов с мета данными
   */
  mapBackupMeta: (state: BackupState): Partial<TWrMapMeta> => {
    return state.mapBackupMeta
  },

  /**
   * defaultBackupMeta
   * ? Дефолтные мета данные содержимого бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TWrLocalMeta} дефолтные мета данные содержимого бэкапа
   */
  defaultBackupMeta: (state: BackupState, getters): TWrLocalMeta => {
    return {
      ...state.defaultBackupMeta,
      order_by:
        getters.isSubtypeBackupMobile &&
        (state.currentFolder === '' || getters.isBackupMobileCategoryContacts)
          ? 'name'
          : 'updated_at',
    }
  },

  /**
   * backupDirectorySourcePath
   * ? Исходный путь директорий элементов бэкапов
   *
   * @param {BackupState} state data
   * @returns {string[]} исходный путь директорий элементов бэкапов
   */
  backupDirectorySourcePath: (state: BackupState): string[] => {
    return state.backupDirectorySourcePath
  },

  /**
   * backupDirectoryNavigationPath
   * ? Навигационный путь директорий элементов бэкапа (для хлебных крошек)
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {string[]} навигационный путь директорий элементов бэкапа
   */
  backupDirectoryNavigationPath: (state: BackupState, getters): string[] => {
    const { isSubtypeBackupMobile, currentBackupDisplayName } = getters
    const directories = state.backupDirectorySourcePath.map((name: string) => {
      if (isSubtypeBackupMobile) {
        const mobileCategory = `mobile/${name}`
        const typeCategory = WR_MIME_TYPE_CATEGORY[mobileCategory]

        return typeCategory ? String(App.$i18n.t(typeCategory.label)) : name
      }

      return name
    })

    return [currentBackupDisplayName, ...directories]
  },

  /**
   * currentView
   * ? Текущий вид резервных копий
   *
   * @param {BackupState} state data
   * @returns {string} текущий вид резервных копий
   */
  currentView: (state: BackupState): string => {
    return state.currentView
  },

  /**
   * currentBackupFolder
   * ? Имя текущей директории (папки) бэкапа
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей директории (папки) бэкапа
   */
  currentBackupFolder: (state: BackupState): string => {
    return state.currentFolder
  },

  /**
   * currentBackupSubtype
   * ? Тип текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} тип текущей резервной копии
   */
  currentBackupSubtype: (state: BackupState): string => {
    return state.currentBackupSubtype
  },

  /**
   * currentBackupName
   * ? Имя текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей резервной копии
   */
  currentBackupName: (state: BackupState): string => {
    return state.currentBackupName
  },

  /**
   * currentBackupDisplayName
   * ? Текущее отображаемое имя бэкапа
   *
   * @param {BackupState} state data
   * @returns {string} текущее отображаемое имя бэкапа
   */
  currentBackupDisplayName: (state: BackupState): string => {
    return state.currentBackup?.display_name ?? ''
  },

  /**
   * currentBackupVersion
   * ? Текущая версия резервной копии
   *
   * @param {BackupState} state data
   * @returns {TWrBackupVersion | null} текущая версия резервной копии
   */
  currentBackupVersion: (state: BackupState): TWrBackupVersion | null => {
    return state.currentBackupVersion
  },

  /**
   * currentBackupVersionName
   * ? Название текущей версия резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {string} название текущей версия резервной копии
   */
  currentBackupVersionName: (state: BackupState, getters): string => {
    return getters.currentBackupVersion?.name ?? ''
  },

  /**
   * currentBackupVersionList
   * ? Список версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {TWrBackupVersion[]} список версий текущей резервной копий
   */
  currentBackupVersionList: (state: BackupState): TWrBackupVersion[] => {
    return cloneDeep(state.currentBackupVersionList).sort(
      (a: TWrBackupVersion, b: TWrBackupVersion) =>
        Number(b.name) - Number(a.name)
    )
  },

  /**
   * currentDirectoryBackupItem
   * ? Текущий элемент директории бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TWrContentItems} текущий элемент директории бэкапа
   */
  currentDirectoryBackupItem: (
    state: BackupState,
    getters
  ): TWrContentItems => {
    return state.mapBackupData[getters.currentBackupPathParams.backupPath] ?? {}
  },

  /**
   * currentDirectoryBackupMeta
   * ? Текущие мета данные директории бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TWrLocalMeta} текущие мета данные директории бэкапа
   */
  currentDirectoryBackupMeta: (state: BackupState, getters): TWrLocalMeta => {
    return (
      state.mapBackupMeta[getters.currentBackupPathParams.backupPath] ??
      state.defaultBackupMeta
    )
  },

  /**
   * currentBackup
   * ? Текущая резервная копия
   *
   * @param {BackupState} state data
   * @returns {TWrBackupInfoFormatted | null} дерево мета данных директорий резервной копии
   */
  currentBackup: (state: BackupState): TWrBackupInfoFormatted | null => {
    if (state.currentBackup === null) {
      return null
    }

    const { size, name, subtype, updated_at, display_name, created_at } =
      state.currentBackup

    const params = { name, versions: state.currentBackupVersionList }

    const lastUpdatedDate = getLastUpdatedDate(params)
    const versions = getCurrentVersions(params)

    return {
      ...state.currentBackup,
      icon: getWrIcons(subtype),
      amountVersion: versions.length,
      formatCreatedDate: getFormattedDate(created_at),
      formatTotalSize: getFormattedSize(size),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatLastUpdatedDateTime: getFormattedDate(lastUpdatedDate, true),
    }
  },

  /**
   * currentBackupDirectory
   * ? Текущая (выбранная) директория резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TWrContentItem[]} текущая (выбранная) директория резервной копии
   */
  currentBackupDirectory: (state: BackupState, getters): TWrContentItem[] => {
    const { currentDirectoryBackupItem, isBackupMobileCategoryContacts } =
      getters
    const backupItems: TWrContentItemResponse[] =
      currentDirectoryBackupItem.items ?? []

    return backupItems.map((backupItem: TWrContentItemResponse) =>
      getFormattedBackupItem(backupItem, isBackupMobileCategoryContacts)
    )
  },

  /**
   * currentBackupDirectoryItem
   * ? Данные по текущей директории резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TWrContentItem | null} данные по текущей директории резервной копии
   */
  currentBackupDirectoryItem: (
    state: BackupState,
    getters
  ): TWrContentItem | null => {
    const directoryItem = state.currentBackupDirectoryItem

    if (directoryItem === null) {
      return null
    }

    return getFormattedBackupItem(
      directoryItem,
      getters.isBackupMobileCategoryContacts
    )
  },

  /**
   * currentBackupPathParams
   * ? Параметры текущего пути директории
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {TBackupPathParams} параметры текущего пути директории
   */
  currentBackupPathParams: (state: BackupState, getters): TBackupPathParams => {
    const {
      backupDirectorySourcePath,
      currentBackupName,
      currentBackupDisplayName,
      currentBackupVersionName,
    } = getters

    const backup = currentBackupName
    const path = backupDirectorySourcePath.join('/')
    const version = currentBackupVersionName
    const folder = backupDirectorySourcePath.at(-1) ?? currentBackupDisplayName
    const backupPath = [backup, version, path].filter((item) => item).join('/')

    return {
      path,
      backup,
      folder,
      version,
      backupPath,
    }
  },

  /**
   * hasBackupVersion
   * ? Признак что версия бэкапа существует
   *
   * @param {BackupState} state data
   * @returns {boolean} признак что версия бэкапа существует
   */
  hasBackupVersion: (state: BackupState): boolean => {
    return state.currentBackupVersion !== null
  },

  /**
   * isEncryptedBackup
   * ? Признак зашифрованного бэкапа
   *
   * @param {BackupState} state data
   * @returns {boolean} признак зашифрованного бэкапа
   */
  isEncryptedBackup: (state: BackupState): boolean => {
    return Boolean(state.currentBackup?.encrypted)
  },

  /**
   * isCachedCurrentDirectoryBackupItem
   * ? Признак того, что элементы бэкапа за кешированы
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {boolean} признак того, что элементы бэкапа за кешированы
   */
  isCachedCurrentDirectoryBackupItem: (
    state: BackupState,
    getters
  ): boolean => {
    return getters.currentBackupPathParams.backupPath in state.mapBackupData
  },

  /**
   * isLoadingCurrentBackup
   * ? Процесс загрузки текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущей резервной копий
   */
  isLoadingCurrentBackup: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackup
  },

  /**
   * isLoadingUrlBackupImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlBackupImage: (state: BackupState): boolean => {
    return state.isLoadingUrlBackupImage
  },

  /**
   * isSubtypeBackupMobile
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isSubtypeBackupMobile: (state: BackupState): boolean => {
    return state.currentBackupSubtype === 'mobile'
  },

  /**
   * isBackupMobileCategoryContacts
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isBackupMobileCategoryContacts: (state: BackupState): boolean => {
    const [, contacts] = MOBILE_CATEGORY_BACKUPS.CONTACTS.split('/')

    return state.currentFolder === contacts
  },

  /**
   * isLoadingCurrentBackupVersionList
   * ? Процесс загрузки списка версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки списка версий текущей резервной копий
   */
  isLoadingCurrentBackupVersionList: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupVersionList
  },

  /**
   * isLoadingFirstDirectoryBackupItems
   * ? Процесс загрузки первых директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки первых директорий резервной копии
   */
  isLoadingFirstDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingFirstDirectoryBackupItems
  },

  /**
   * isLoadingNextDirectoryBackupItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingNextDirectoryBackupItems
  },
}
