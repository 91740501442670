import type {
  IDaDataCompanyByItemData,
  IDaDataCompanyByPayload,
  IDaDataCompanyResponse,
  IDaDataCompanyRuItemData,
  IDaDataCompanyRuPayload,
  TDaDataLocalCompanyPayload,
} from '@/api/services/dadata/types'
import type { AxiosRequestConfig } from 'axios'
import type { TCountry, ICompany, TOrganizationType } from '@/api/types'

import axios from '@/api/axios'
import { COUNTRIES } from '@/config/constants'

const getDaDataAxiosConfig = (): AxiosRequestConfig => ({
  headers: {
    Accept: 'application/json',
    Authorization: `Token ${process.env.VUE_APP_DADATA_API_KEY}`,
    'Content-Type': 'application/json',
  },
})

export default {
  /** ==== Организация ==== */

  /**
   * getSuggestionsRuCompany
   * ? Вернуть все доступные сведения о Российских компаниях
   *
   * @param {IDaDataCompanyRuPayload} data фильтры для поиска
   * @returns {Promise<ICompany[]>} вся информация по Российским компаниям
   */
  getSuggestionsRuCompany(data: IDaDataCompanyRuPayload): Promise<ICompany[]> {
    return axios
      .post<
        IDaDataCompanyResponse<IDaDataCompanyRuItemData>,
        IDaDataCompanyResponse<IDaDataCompanyRuItemData>
      >(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
        data,
        getDaDataAxiosConfig()
      )
      .then((response: IDaDataCompanyResponse<IDaDataCompanyRuItemData>) => {
        return response.suggestions.map((item) => ({
          id: item.data.hid,
          name: item.value,
          type: (item.data.type?.toLowerCase() ?? '') as TOrganizationType,
          address: item.data.address.value || '',
          inn: item.data.inn,
          ogrn: item.data.ogrn,
          kpp: item.data.kpp,
          oked: '',
          unp: '',
          country: COUNTRIES.RU,
        }))
      })
  },

  /**
   * getSuggestionsByCompany
   * ? Вернуть все доступные сведения о Белорусских компаниях
   *
   * @param {IDaDataCompanyByPayload} data фильтры для поиска
   * @returns {Promise<ICompany[]>} вся информация по Белорусским компаниям
   */
  getSuggestionsByCompany(data: IDaDataCompanyByPayload): Promise<ICompany[]> {
    return axios
      .post<
        IDaDataCompanyResponse<IDaDataCompanyByItemData>,
        IDaDataCompanyResponse<IDaDataCompanyByItemData>
      >(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party_by',
        data,
        getDaDataAxiosConfig()
      )
      .then((response) => {
        return response.suggestions.map((item, key) => ({
          id: key.toString(),
          name: item.value,
          type: (item.data.type?.toLowerCase() || '') as TOrganizationType,
          address: item.data.address || '',
          oked: item.data.oked || '',
          unp: item.data.unp,
          inn: '',
          ogrn: '',
          kpp: '',
          country: COUNTRIES.BY,
        }))
      })
  },

  /**
   * getSuggestionsCompany
   * ? Вернуть все доступные сведения о компаниях
   *
   * @param {TDaDataLocalCompanyPayload} data Параметры поиска
   * @param {TCountry} country Название страны
   * @returns {Promise<ICompany[]>} вся информация по компаниям
   */
  getSuggestionsCompany(
    data: TDaDataLocalCompanyPayload,
    country: TCountry
  ): Promise<ICompany[]> {
    const api = {
      ru: this.getSuggestionsRuCompany.bind(this, {
        query: data.query,
        count: data.count,
        status: [data.status],
      }),
      by: this.getSuggestionsByCompany.bind(this, {
        query: data.query,
        filters: { status: data.status },
      }),
    } as const

    if (!(country in api)) {
      throw new Error('Invalid country type: ' + country)
    }

    return api[country]()
  },
}
