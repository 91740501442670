import { TNotEmptyString } from '@/types'

/**
 * isEmptyObject
 * ? Признак пустого объекта
 *
 * @param {unknown} value значение
 * @returns {boolean} признак пустого объекта
 */
export const isEmptyObject = (value: unknown): boolean => {
  if (typeof value !== 'object') {
    return true
  }

  return Object.keys(value || {}).length === 0
}

/**
 * isStringAsBoolean
 * ? Является ли "строковое" значение булевым типом
 *
 * @param {unknown} value значение
 * @returns {boolean} признак булева
 */
export const isStringAsBoolean = (value: unknown): boolean => {
  return isString(value) && ['true', 'false'].includes(value)
}

/**
 * isString
 * ? Является ли значение строкой
 *
 * @param {unknown} value значение
 * @returns {boolean} признак строки
 */
export const isString = (value: unknown): value is string => {
  return typeof value === 'string'
}

/**
 * isNumber
 * ? Является ли значение числом
 *
 * @param {unknown} value значение
 * @returns {boolean} признак числа
 */
export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number' && !Number.isNaN(value)
}

/**
 * isNotEmptyString
 * ? Является ли значение не пустой строкой
 *
 * @param {unknown} value значение
 * @returns {boolean} признак не пустой строки
 */
export const isNotEmptyString = <T>(value: T): value is TNotEmptyString<T> => {
  return isString(value) && value.length > 0
}

/**
 * isNumeric
 * ? Является ли значение числом
 *
 * @param {any} value значение
 * @returns {boolean} признак числа
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumeric = (value: any): boolean => {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0)
}

/**
 * isISODate
 * ? Является ли строковое значение датой в формате ISO/JSON
 *
 * @param {unknown} value значение
 * @returns {boolean} признак даты в формате ISO/JSON
 */
export const isISODate = (value: unknown): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d*Z$/.test(value)
}
